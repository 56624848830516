// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
    margin-top: 11px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.badge {
    position: absolute;
    top: 12px;
    right: -8px;
    padding: 0px 6px;
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 14px;
}

.notification-box {
    position: absolute;
    width: 252px;
    color: black;
    background: white;
    z-index: 99;
    padding: 15px;
}

.request-box {
    display: flex;
}

.notification-img {
    width: 45px;
    height: 45px;
    border-radius: 25px;
    margin: 0px 10px 11px 0px
}

.request-btn-row {
    text-align: center;
}

.request-btn-row .friend-request {
    width: 30%;
    margin: 4px 5px 25px 4px;
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 0px;
    cursor: pointer;
}

.request-btn-row .accept-request {
    background-color: #41c764;
    color: #fff;
}

.request-btn-row .decline-request {
    background-color: #FF6666;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Notification/notification.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".notification {\n    margin-top: 11px;\n    color: white;\n    font-size: 18px;\n    cursor: pointer;\n}\n\n.badge {\n    position: absolute;\n    top: 12px;\n    right: -8px;\n    padding: 0px 6px;\n    border-radius: 50%;\n    background: red;\n    color: white;\n    font-size: 14px;\n}\n\n.notification-box {\n    position: absolute;\n    width: 252px;\n    color: black;\n    background: white;\n    z-index: 99;\n    padding: 15px;\n}\n\n.request-box {\n    display: flex;\n}\n\n.notification-img {\n    width: 45px;\n    height: 45px;\n    border-radius: 25px;\n    margin: 0px 10px 11px 0px\n}\n\n.request-btn-row {\n    text-align: center;\n}\n\n.request-btn-row .friend-request {\n    width: 30%;\n    margin: 4px 5px 25px 4px;\n    border-radius: 5px;\n    border: 2px solid transparent;\n    padding: 0px;\n    cursor: pointer;\n}\n\n.request-btn-row .accept-request {\n    background-color: #41c764;\n    color: #fff;\n}\n\n.request-btn-row .decline-request {\n    background-color: #FF6666;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
