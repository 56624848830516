// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-stories h2 {
  color: #800925;
}

.story {
  display: flex;
}

.story-img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

/* .card {
  border: 1px solid black;
} */
.succes {
 
    font-size: 29px;
    color: #8e0026;
    font-weight: 600;
}
a.card-span {
    font-size: 12px;
    color: #8e0026!important;
   
}

.CustomLoader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: #0000002b;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

.CustomLoader .spinner-border {
  border-color: #fff;
  border-right-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/Stories.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;;GAEG;AACH;;IAEI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,wBAAwB;;AAE5B;;AAEA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,qBAAqB;EACrB,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;AACjC","sourcesContent":[".success-stories h2 {\n  color: #800925;\n}\n\n.story {\n  display: flex;\n}\n\n.story-img {\n  width: 100%;\n  height: 220px;\n  object-fit: cover;\n}\n\n/* .card {\n  border: 1px solid black;\n} */\n.succes {\n \n    font-size: 29px;\n    color: #8e0026;\n    font-weight: 600;\n}\na.card-span {\n    font-size: 12px;\n    color: #8e0026!important;\n   \n}\n\n.CustomLoader {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 99;\n  background: #0000002b;\n  backdrop-filter: blur(8px);\n}\n\n.CustomLoader .spinner-border {\n  border-color: #fff;\n  border-right-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
