// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1200px) {
  .container {
    max-width: 1320px;
  }
}

.cards-wrapper {
  padding: 100px 0px !important;
  margin: 12px;
}

.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Payment/Payment.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,iDAAiD;AACnD;;AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EACzB,SAAS;EACT,kBAAkB;EAClB,iDAAiD;EACjD,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":["@media (min-width: 1200px) {\n  .container {\n    max-width: 1320px;\n  }\n}\n\n.cards-wrapper {\n  padding: 100px 0px !important;\n  margin: 12px;\n}\n\n.card {\n  background-color: white;\n  padding: 10px 20px 11px;\n  border-radius: 5px;\n  width: 100%;\n  border: 1px solid #afafaf;\n  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);\n}\n\n.pay-button {\n  padding: 0.7rem 2rem;\n  width: 100%;\n  margin: 1rem 0;\n  color: white;\n  font-weight: bold;\n  font-size: medium;\n  background-color: #556cd6;\n  border: 0;\n  border-radius: 5px;\n  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);\n  transition: box-shadow 500ms;\n  cursor: pointer;\n}\n\n.pay-button:disabled {\n  background-color: #afafaf;\n  box-shadow: none;\n  cursor: default;\n}\n\n.pay-button:disabled:hover {\n  box-shadow: none;\n}\n\n.pay-button:hover {\n  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
