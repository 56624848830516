// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-block 
 {
    position: relative;
    margin-bottom: 60px;
}

.about-section {
    position: relative;
    padding: 50px 0 5px;
}
.speakers-section {
    position: relative;
    padding: 30px 0 25px;
}
.app-section {
    padding-top: 0px;
    padding-bottom: 0px;
}
.keepin_Sign
{
    margin-top: 10px;
}

@media only screen and (max-width: 767px)
{
.sec-title {
    margin-bottom: 40px;
    margin-top: 12px;
}
.about-section .icon-circle-blue {
    display: none;
}
.features-section-two {
    padding-top: 0px;
    padding-bottom: 0px;
}
.about-section .content-column {
    position: relative;
    margin-bottom: 5px;
}
.app-section {
   display: none;
}

.content-column {
    margin-top: 0px;
}
.about-section .image-column {
    display: none;
}

}

`, "",{"version":3,"sources":["webpack://./src/assets/css/mystyle.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;;IAEI,gBAAgB;AACpB;;AAEA;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;AACA;GACG,aAAa;AAChB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,aAAa;AACjB;;AAEA","sourcesContent":[".news-block \r\n {\r\n    position: relative;\r\n    margin-bottom: 60px;\r\n}\r\n\r\n.about-section {\r\n    position: relative;\r\n    padding: 50px 0 5px;\r\n}\r\n.speakers-section {\r\n    position: relative;\r\n    padding: 30px 0 25px;\r\n}\r\n.app-section {\r\n    padding-top: 0px;\r\n    padding-bottom: 0px;\r\n}\r\n.keepin_Sign\r\n{\r\n    margin-top: 10px;\r\n}\r\n\r\n@media only screen and (max-width: 767px)\r\n{\r\n.sec-title {\r\n    margin-bottom: 40px;\r\n    margin-top: 12px;\r\n}\r\n.about-section .icon-circle-blue {\r\n    display: none;\r\n}\r\n.features-section-two {\r\n    padding-top: 0px;\r\n    padding-bottom: 0px;\r\n}\r\n.about-section .content-column {\r\n    position: relative;\r\n    margin-bottom: 5px;\r\n}\r\n.app-section {\r\n   display: none;\r\n}\r\n\r\n.content-column {\r\n    margin-top: 0px;\r\n}\r\n.about-section .image-column {\r\n    display: none;\r\n}\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
