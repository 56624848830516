// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropselect{
	padding: 9px 9px;
	height: 43px;
	width: 104%;
}
select.dropselect {
    border-radius: 50px;
}
.search
{
	color:#ffffff;
}
button.theme-btn.btn.btn-style-two.btn-style-letsbegin {
    border-radius: 50px;
    padding: 4px 14px;
    color: #8e0026;
    border: 2px solid #8e0026;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/newcss.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,YAAY;CACZ,WAAW;AACZ;AACA;IACI,mBAAmB;AACvB;AACA;;CAEC,aAAa;AACd;AACA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".dropselect{\r\n\tpadding: 9px 9px;\r\n\theight: 43px;\r\n\twidth: 104%;\r\n}\r\nselect.dropselect {\r\n    border-radius: 50px;\r\n}\r\n.search\r\n{\r\n\tcolor:#ffffff;\r\n}\r\nbutton.theme-btn.btn.btn-style-two.btn-style-letsbegin {\r\n    border-radius: 50px;\r\n    padding: 4px 14px;\r\n    color: #8e0026;\r\n    border: 2px solid #8e0026;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
